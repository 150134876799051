import style from "./Header.module.scss"

export const Header = () => {
    return (
        <header className={style.header}>
            <header className={style.inner}>
                <p className={style.logo}>crud</p>
            </header>
        </header>
    )
}