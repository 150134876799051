import {AppStore} from "./AppStore";
import {StoreContext} from "../components/App/AppContainer";
import {useContext} from "react";
import {CategoryStore} from "./CategoryStore";

export class RootStore {
    appStore: AppStore;
    categoryStore: CategoryStore;

    constructor() {
        this.appStore = new AppStore();
        this.categoryStore = new CategoryStore();
    }
}
export const store = new RootStore()

export const useStore = (): RootStore => useContext(StoreContext)
