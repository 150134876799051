import style from "./CreateCategoryForm.module.scss"
import {FormikErrors, FormikHelpers, useFormik} from "formik";
import {ICreateCategoryValues} from "../../../types/category.types";
import {Button, TextField, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../store/RootStore";
import {categoryAPI} from "../../../api/category.api";

export const CreateCategoryForm = observer(() => {
    const {
        categoryStore: {
            setCategories,
            getCategories
        }
    } = useStore()

    const initialValues = {
        name: ""
    }

    const onSubmit = async (values: ICreateCategoryValues, helpers: FormikHelpers<ICreateCategoryValues>) => {
        try {
            await categoryAPI.create(values);
            await getCategories();
            console.log(values);
            helpers.resetForm();
        } catch (e: any) {
            console.log(e.response.data.message);
            if (e.response.data.message === "category already exist") {
               return helpers.setFieldError("name", "category already exist")
            }
            helpers.resetForm();
        } finally {
            //
        }
    }

    const validate = (values: ICreateCategoryValues): FormikErrors<ICreateCategoryValues> => {
        const errors = {} as FormikErrors<ICreateCategoryValues>

        if (!values.name) {
            errors.name = "Required"
        }
        return errors
    }

    const formik= useFormik({
        initialValues,
        validate,
        onSubmit
    });

    return (
        <div className={style.createCategoryForm}>
            <Typography variant="h6"
                        color="secondary"
            >
                Create category
            </Typography>
            <form onSubmit={formik.handleSubmit}
                  className={style.form}
            >
                <TextField
                    size="small"
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <Button color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        className={style.submitBtn}
                >
                    Create
                </Button>

            </form>

        </div>
    )
})