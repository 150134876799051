import React from 'react';
import style from "./App.module.scss";
import {Header} from "../Header/Header";
import {Content} from "../Content/Content";

export const App = () => {
    return (
        <div className={style.app}>
            <Header/>
            <Content/>
        </div>
    );
}

