import style from "./Content.module.scss";
import {Typography} from "@mui/material";
import {clsx} from "clsx";
import {FormikHelpers, useFormik} from "formik";
import {CreateCategoryForm} from "./CreateCategoryForm/CreateCategoryForm";
import {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../store/RootStore";




export const Content = observer(() => {
    const {
        categoryStore: {
            getCategories,
            categories
        }
    } = useStore();

    useEffect(() => {
        const getHandler = async () => {
            try {
                getCategories();
            } catch (e) {

            } finally {

            }
        }
        getHandler().then();
    }, [])

    return (
        <div className={style.content}>
            <div className={style.inner}>

                <div className={style.categoriesBlock}>
                    <Typography variant="h5"
                                color="secondary"
                    >
                        Categories
                    </Typography>

                    <div className={style.categoriesList}>
                        {
                            categories.length === 0 ? (
                                <Typography variant="h5"
                                            color="secondary"
                                >
                                    No Categories
                                </Typography>
                            ) : (
                                <>
                                    {
                                      categories.map(category => (
                                         <p key={category.id}>{category.name}</p>
                                      ))
                                    }
                                </>
                            )
                        }
                    </div>

                    <CreateCategoryForm/>
                </div>

                <div className={style.productsBlock}>
                    <Typography variant="h5"
                                color="secondary"
                    >
                        Products
                    </Typography>

                </div>


            </div>
        </div>
    )
})