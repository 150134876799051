import {action, makeObservable, observable} from "mobx";
import {ICategory, ICreateCategoryValues} from "../types/category.types";
import {categoryAPI} from "../api/category.api";

export class CategoryStore {
    categories = [] as ICategory[]
    selectedCategoryId: string = ""

    constructor() {
        makeObservable(this,
            {
                categories: observable,
                selectedCategoryId: observable,

                setSelectedCategoryId: action.bound,
                setCategories: action.bound,
                getCategories: action.bound,
                createCategory: action.bound,
            }
        )
    }

    setSelectedCategoryId(selectedCategoryId: string) {
        this.selectedCategoryId = selectedCategoryId;
    }

    setCategories(categories: ICategory[]) {
        this.categories = categories
    }

    async getCategories() {
        try {
             const categories = await categoryAPI.getAll();
             this.setCategories(categories);
        } catch (e) {
            console.log(e)
        }
    }

    async createCategory(data: ICreateCategoryValues) {
        try {

        } catch (e) {
            console.log(e)
        }
    }



}